
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue';
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'

@Options({
  components: {
    PagePlugin
  }
})
export default class evaluate_assignment extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name);
  }

  t_all_data=[]

t_total= 0;
t_search_data = {
  results_name: ''

}

rule_t_search_data = {

}

t_detail_dialog = {
  open: false,
  title: '详情'
}

t_detail_data = {
  evaluate_info: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: []

  },
  base_info: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      is_evaluate: 0,
      evaluator_type: 0,
      purpose: 0,
      finish_time: '',
      fee: 0,
      entrust_contact: '',
      entrust_phone: '',
      wechat: '',
      email: '',
      remark: '',
      name: '',
      code: '',
      modality: 0,
      res_source: 0,
      res_source_unit: '',
      source_unit_nature: 0,
      project_name: '',
      type: 0,
      industry_category_id: [],
      related_industry_ids: [],
      custome_related_industries: '',
      contact: '',
      phone: '',
      finish_units: [] as any[]

    }

  },
  profile_info: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      summary: [] as any[]

    }

  },
  wbs_deconstruct: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      wbs: [] as any[]

    }

  },
  techinque_level: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      core_technologies: [] as any[],
      techinque_level: {
        maturity: 0,
        maturity_reason: '',
        innovation: 0,
        innovation_reason: '',
        advanced: 0,
        advanced_reason: ''

      },
      description: '',
      question_suggest: ''

    }

  },
  bmk_analysis: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      obj1_info: '',
      obj2_info: '',
      bmk: [] as any[]

    }

  },
  promotion: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      extend: [] as any[]

    }

  },
  economic_benefit: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      economic_benefit: [] as any[]

    }

  },
  social_benefit: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      social_benefit: [] as any[]

    }

  },
  intellectual_property: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      intellectual_property: [] as any[]

    }

  },
  team: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      team_table: [] as any[],
      team_txt: [] as any[]

    }

  },
  risk: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      risk: [] as any[]

    }

  },
  input_and_output: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      end_time: '',
      facility_charge: 0,
      materials_expenses: 0,
      processing_costs: 0,
      laboratory_fee: 0,
      power_cost: 0,
      travel_expense: 0,
      conference_fee: 0,
      communication_fee: 0,
      transaction_fee: 0,
      service_fee: 0,
      consulting_fee: 0,
      administrative_fee: 0,
      incentive_fee: 0,
      rest_fee: 0,
      sales_revenue_fee: 0,
      append_fee: 0,
      total_fee: 0,
      profit_fee: 0,
      income_tax_rate_fee: 0,
      income_tax_fee: 0,
      profit_afte_tax_fee: 0,
      output_description: ''

    }

  },
  technology_novelty: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      check_organization: '',
      report: '',
      report_date: '',
      novelty_point: '',
      search_results: '',
      other_notes: ''

    }

  },
  detect_test: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      check_organization: '',
      testing_institutions: '',
      detected_name: '',
      report: '',
      report_date: '',
      search_results: '',
      other_notes: ''

    }

  },
  technical_valuation: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      valuation: [] as any[]

    }

  },
  financing_needs: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      valuation: [] as any[]

    }

  },
  files: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      files: [] as any[]

    }

  },
  res_menus: [] as any[]

}

t_edit_dialog = {
  open: false,
  title: '要求补充材料'
}

t_edit_data = {
  id: 0,
  materials_title: '',
  materials_content: ''

}

rule_t_edit_data = {

}

t_edit1_dialog = {
  open: false,
  title: '查看补充材料'
}

t_edit1_data = {
  id: 0,
  materials_title: '',
  materials_content: '',
  materials: [] as any[]

}

rule_t_edit1_data = {

}

t_edit2_dialog = {
  open: false,
  title: '专家评估'
}

t_edit2_data = {
  id: 0,
  opinion_items: [] as any[],
  evaluate_info: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: []

  },
  base_info: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      is_evaluate: 0,
      evaluator_type: 0,
      purpose: 0,
      finish_time: '',
      fee: 0,
      entrust_contact: '',
      entrust_phone: '',
      wechat: '',
      email: '',
      remark: '',
      name: '',
      code: '',
      modality: 0,
      res_source: 0,
      res_source_unit: '',
      source_unit_nature: 0,
      project_name: '',
      type: 0,
      industry_category_id: [],
      related_industry_ids: [],
      custome_related_industries: '',
      contact: '',
      phone: '',
      finish_units: [] as any[]

    }

  },
  profile_info: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      summary: [] as any[]

    }

  },
  wbs_deconstruct: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      wbs: [] as any[]

    }

  },
  techinque_level: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      core_technologies: [] as any[],
      techinque_level: {
        maturity: 0,
        maturity_reason: '',
        innovation: 0,
        innovation_reason: '',
        advanced: 0,
        advanced_reason: ''

      },
      description: '',
      question_suggest: ''

    }

  },
  bmk_analysis: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      obj1_info: '',
      obj2_info: '',
      bmk: [] as any[]

    }

  },
  promotion: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      extend: [] as any[]

    }

  },
  economic_benefit: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      economic_benefit: [] as any[]

    }

  },
  social_benefit: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      social_benefit: [] as any[]

    }

  },
  intellectual_property: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      intellectual_property: [] as any[]

    }

  },
  team: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      team_table: [] as any[],
      team_txt: [] as any[]

    }

  },
  risk: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      risk: [] as any[]

    }

  },
  input_and_output: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      end_time: '',
      facility_charge: 0,
      materials_expenses: 0,
      processing_costs: 0,
      laboratory_fee: 0,
      power_cost: 0,
      travel_expense: 0,
      conference_fee: 0,
      communication_fee: 0,
      transaction_fee: 0,
      service_fee: 0,
      consulting_fee: 0,
      administrative_fee: 0,
      incentive_fee: 0,
      rest_fee: 0,
      sales_revenue_fee: 0,
      append_fee: 0,
      total_fee: 0,
      profit_fee: 0,
      income_tax_rate_fee: 0,
      income_tax_fee: 0,
      profit_afte_tax_fee: 0,
      output_description: ''

    }

  },
  technology_novelty: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      check_organization: '',
      report: '',
      report_date: '',
      novelty_point: '',
      search_results: '',
      other_notes: ''

    }

  },
  detect_test: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      check_organization: '',
      testing_institutions: '',
      detected_name: '',
      report: '',
      report_date: '',
      search_results: '',
      other_notes: ''

    }

  },
  technical_valuation: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      valuation: [] as any[]

    }

  },
  financing_needs: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      valuation: [] as any[]

    }

  },
  files: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      files: [] as any[]

    }

  },
  res_menus: [] as any[]

}

rule_t_edit2_data = {

}

t_edit3_dialog = {
  open: false,
  title: '向平台申诉'
}

t_edit3_data = {
  id: 0,
  result_id: 0,
  appeals_content: ''

}

rule_t_edit3_data = {

}

t_edit4_dialog = {
  open: false,
  title: '同意/拒绝邀请'
}

t_edit4_data = {
  id: 0,
  user_applies_status: 0

}

rule_t_edit4_data = {

}

evaluator_type_377 = new Map<any, string>([[1, '第三方评'], [2, '公开邀请'], [3, '自邀专家']])
invite_type_380 = new Map<any, string>([[1, '公开邀请'], [2, '定向邀请']])
type_384 = new Map<any, string>([[1, '自评'], [2, '专家'], [3, '评估机构']])
status_385 = new Map<any, string>([[1, '草稿'], [2, '待审核'], [3, '退回'], [4, '已完成']])
score_403 = new Map<any, string>([[1, '1'], [2, '2'], [3, '3'], [4, '4'], [5, '5'], [6, '6'], [7, '7'], [8, '8'], [9, '9'], [10, '10']])
user_applies_status_408 = new Map<any, string>([[1, '同意'], [2, '拒绝']])
is_evaluate_419 = new Map<any, string>([[0, '否'], [1, '是']])
evaluator_type_420 = new Map<any, string>([[1, '第三方评'], [2, '公开邀请评估'], [3, '自邀专家评']])
purpose_421 = new Map<any, string>([[1, '技术交易(包含转让、许可、作价投资、质押融资等)'], [2, '应用推广(包含市场推广、项目引进等)'], [3, '水平鉴定(包含报奖、人才评价、成果管理、创新规划等)'], [4, '项目管理(包含项目立项、项目监督、项目结题等)']])
modality_431 = new Map<any, string>([[1, '新方法'], [2, '新产品'], [3, '新工艺'], [4, '新材料/配方'], [5, '新设备/工具'], [6, '软件系统'], [7, '新品种'], [8, '其他']])
res_source_432 = new Map<any, string>([[1, '纵向'], [2, '横向'], [3, '其他']])
source_unit_nature_434 = new Map<any, string>([[1, '高校'], [2, '科研机构'], [3, '其他事业单位'], [4, '社会组织'], [5, '国企'], [6, '名企'], [7, '外企'], [8, '混合所有制企业'], [9, '其他']])
type_436 = new Map<any, string>([[1, '应用技术'], [2, '基础理论'], [3, '软课题研究'], [4, '教育教学'], [5, '创新能力与条件建设'], [6, '技术转移与推广'], [7, '文学艺术实践'], [8, '其它']])
finsh_way_469 = new Map<any, string>([[1, '独立开发'], [2, '委外开发'], [3, '外部购买']])
is_core_470 = new Map<any, string>([[0, '否'], [1, '是']])
view_sort_471 = new Map<any, string>([[1, '1'], [2, '2'], [3, '3'], [4, '4'], [5, '5']])
result_form_472 = new Map<any, string>([[1, '服务业']])
before_maturity_473 = new Map<any, string>([])
after_maturity_474 = new Map<any, string>([])
maturity_489 = new Map<any, string>([[0, '0'], [1, '1'], [2, '2'], [3, '3'], [4, '4'], [5, '5'], [6, '6'], [7, '7'], [8, '8'], [9, '9'], [10, '10']])
innovation_491 = new Map<any, string>([[0, '0'], [1, '1'], [2, '2'], [3, '3'], [4, '4'], [5, '5'], [6, '6'], [7, '7'], [8, '8'], [9, '9'], [10, '10']])
advanced_493 = new Map<any, string>([[0, '0'], [1, '1'], [2, '2'], [3, '3'], [4, '4'], [5, '5'], [6, '6'], [7, '7'], [8, '8'], [9, '9'], [10, '10']])

t_get_all_data(offset = 0, length = 10) {
  const postData = {
    offset: offset || 0,
    length: length || 10,
    results_name: this.t_search_data.results_name

  }
  Api.http_evaluate_assignmentt0(postData).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_all_data = res.data.data
      this.t_total = res.data.total
    }
  })
}
t_detail_init(row) {

  Api.http_evaluate_assignmenttget4({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_detail_data.evaluate_info = res.data.evaluate_info;
      this.t_detail_data.base_info = res.data.base_info;
      this.t_detail_data.profile_info = res.data.profile_info;
      this.t_detail_data.wbs_deconstruct = res.data.wbs_deconstruct;
      this.t_detail_data.techinque_level = res.data.techinque_level;
      this.t_detail_data.bmk_analysis = res.data.bmk_analysis;
      this.t_detail_data.promotion = res.data.promotion;
      this.t_detail_data.economic_benefit = res.data.economic_benefit;
      this.t_detail_data.social_benefit = res.data.social_benefit;
      this.t_detail_data.intellectual_property = res.data.intellectual_property;
      this.t_detail_data.team = res.data.team;
      this.t_detail_data.risk = res.data.risk;
      this.t_detail_data.input_and_output = res.data.input_and_output;
      this.t_detail_data.technology_novelty = res.data.technology_novelty;
      this.t_detail_data.detect_test = res.data.detect_test;
      this.t_detail_data.technical_valuation = res.data.technical_valuation;
      this.t_detail_data.financing_needs = res.data.financing_needs;
      this.t_detail_data.files = res.data.files;
      this.t_detail_data.res_menus = res.data.res_menus;
      this.t_detail_dialog.open = true;

    }
  })

}
t_detail_cancle() {
  this.t_detail_dialog.open = false;
}
t_detail_ok() {
  this.t_detail_dialog.open = false;
}
t_edit_init(row) {

  Api.http_evaluate_assignmenttget5({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_edit_data.id = row.id;
      this.t_edit_data.materials_title = res.data.materials_title;
      this.t_edit_data.materials_content = res.data.materials_content;
      this.t_edit_dialog.open = true;

    }
  })

}
t_edit_cancle() {
  this.t_edit_dialog.open = false;
}
t_edit_ok() {
  this.$refs.ref_t_edit_data.validate(valid => {
    if (valid) {
      const postData = {
        id: this.t_edit_data.id,
        materials_title: this.t_edit_data.materials_title,
        materials_content: this.t_edit_data.materials_content

      }
      Api.http_evaluate_assignmentt5(postData).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.t_edit_dialog.open = false
          this.t_get_all_data(0, 10)
          this.$message.success(JSON.stringify(res.message))
        }
      })
    }
  })
}
t_edit1_init(row) {

  Api.http_evaluate_assignmenttget6({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_edit1_data.id = row.id;
      this.t_edit1_data.materials_title = res.data.materials_title;
      this.t_edit1_data.materials_content = res.data.materials_content;
      this.t_edit1_data.materials = res.data.materials;
      this.t_edit1_dialog.open = true;

    }
  })

}
t_edit1_cancle() {
  this.t_edit1_dialog.open = false;
}
t_edit1_ok() {
  this.$refs.ref_t_edit1_data.validate(valid => {
    if (valid) {
      const postData = {
        id: this.t_edit1_data.id

      }
      Api.http_evaluate_assignmentt6(postData).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.t_edit1_dialog.open = false
          this.t_get_all_data(0, 10)
          this.$message.success(JSON.stringify(res.message))
        }
      })
    }
  })
}
t_edit2_init(row) {

  Api.http_evaluate_assignmenttget7({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_edit2_data.id = row.id;
      this.t_edit2_data.opinion_items = res.data.opinion_items;
      this.t_edit2_data.evaluate_info = res.data.evaluate_info;
      this.t_edit2_data.base_info = res.data.base_info;
      this.t_edit2_data.profile_info = res.data.profile_info;
      this.t_edit2_data.wbs_deconstruct = res.data.wbs_deconstruct;
      this.t_edit2_data.techinque_level = res.data.techinque_level;
      this.t_edit2_data.bmk_analysis = res.data.bmk_analysis;
      this.t_edit2_data.promotion = res.data.promotion;
      this.t_edit2_data.economic_benefit = res.data.economic_benefit;
      this.t_edit2_data.social_benefit = res.data.social_benefit;
      this.t_edit2_data.intellectual_property = res.data.intellectual_property;
      this.t_edit2_data.team = res.data.team;
      this.t_edit2_data.risk = res.data.risk;
      this.t_edit2_data.input_and_output = res.data.input_and_output;
      this.t_edit2_data.technology_novelty = res.data.technology_novelty;
      this.t_edit2_data.detect_test = res.data.detect_test;
      this.t_edit2_data.technical_valuation = res.data.technical_valuation;
      this.t_edit2_data.financing_needs = res.data.financing_needs;
      this.t_edit2_data.files = res.data.files;
      this.t_edit2_data.res_menus = res.data.res_menus;
      this.t_edit2_dialog.open = true;

    }
  })

}
t_edit2_cancle() {
  this.t_edit2_dialog.open = false;
}
t_edit2_ok() {
  this.$refs.ref_t_edit2_data.validate(valid => {
    if (valid) {
      const postData = {
        id: this.t_edit2_data.id,
        opinion_items: this.t_edit2_data.opinion_items

      }
      Api.http_evaluate_assignmentt7(postData).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.t_edit2_dialog.open = false
          this.t_get_all_data(0, 10)
          this.$message.success(JSON.stringify(res.message))
        }
      })
    }
  })
}
t_edit3_init(row) {

  Api.http_evaluate_assignmenttget8({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_edit3_data.id = row.id;
      this.t_edit3_data.result_id = res.data.result_id;
      this.t_edit3_data.appeals_content = res.data.appeals_content;
      this.t_edit3_dialog.open = true;

    }
  })

}
t_edit3_cancle() {
  this.t_edit3_dialog.open = false;
}
t_edit3_ok() {
  this.$refs.ref_t_edit3_data.validate(valid => {
    if (valid) {
      const postData = {
        id: this.t_edit3_data.id,
        result_id: this.t_edit3_data.result_id,
        appeals_content: this.t_edit3_data.appeals_content

      }
      Api.http_evaluate_assignmentt8(postData).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.t_edit3_dialog.open = false
          this.t_get_all_data(0, 10)
          this.$message.success(JSON.stringify(res.message))
        }
      })
    }
  })
}
t_edit4_init(row) {

  Api.http_evaluate_assignmenttget9({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_edit4_data.id = row.id;
      this.t_edit4_data.user_applies_status = res.data.user_applies_status;
      this.t_edit4_dialog.open = true;

    }
  })

}
t_edit4_cancle() {
  this.t_edit4_dialog.open = false;
}
t_edit4_ok() {
  this.$refs.ref_t_edit4_data.validate(valid => {
    if (valid) {
      const postData = {
        id: this.t_edit4_data.id,
        user_applies_status: this.t_edit4_data.user_applies_status

      }
      Api.http_evaluate_assignmentt9(postData).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.t_edit4_dialog.open = false
          this.t_get_all_data(0, 10)
          this.$message.success(JSON.stringify(res.message))
        }
      })
    }
  })
}
t_init() {
  this.t_get_all_data(0, 10);

}
created() {
  this.t_init();

}

}
